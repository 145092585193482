@import '~app/mixins';

$color-border: #c2c5c9;

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-size: 12px;
  //width: $left-side-width - 1;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-border;
    padding: 0 10px;
    height: 40px;
    border-right: 1px solid #c2c5c9;
    font-size: 16px;
    font-weight: bold;
    color: #333;

    .caption {
      @include text-overflow();
    }

    button {
      padding-left: 10px;
      padding-right: 10px;
    }

    .actions {
      .action {
        display: flex;
        align-items: flex-end;
        cursor: pointer;

        svg {
          width: 20px;
          height: 24px;
          margin: 8px;
          pointer-events: none;

          use {
            fill: #bbb;
          }
        }

        &.dropping {
          svg {
            use {
              fill: red;
            }
          }
        }
      }
    }
  }

  .body {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    border-right: 1px solid #c2c5c9;
    background: #fff;
  }
}
